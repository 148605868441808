import React from "react"
import Layout from "../components/layout/layout.component"
import { GlobalStyle } from "../styles/global.style"
import Clients from "../components/client/clients.component"

export default () => (
  <div>
    <GlobalStyle />

    <Layout>
      Software, delivered, for:
      <Clients/>
    </Layout>
  </div>
)
